import { handleErrorWithSentry } from '@sentry/sveltekit';
import { replayIntegration } from '@sentry/browser';
import * as Sentry from '@sentry/sveltekit';
import { captureConsoleIntegration } from '@sentry/integrations';
import { env } from '$env/dynamic/public';

Sentry.init({
  dsn: env.PUBLIC_SENTRY_DNS,
  // tracesSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.03,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  // If you don't want to use Session Replay, just remove the lines below:
  integrations: [
    replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
    }),
    captureConsoleIntegration({ levels: ['error'] }),
  ],
  environment: env.PUBLIC_ENVIRONMENT,
  beforeSend(event: any, hint) {
    if (env.PUBLIC_ENVIRONMENT === 'dev') {
      console.log('Non production error, not sending to sentry');
      return;
    }
    return event; // Send the event to Sentry
  },
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
